import * as UpdateBaseActions from '../actions/update.base.actions';
import * as UpdateDetailsActions from '../actions/update.details.actions';

const initialState = {
  data: {
    patientGroupId: '',
    patientGroupName: '',
    area: '',
    room: '',
    urn: '',
    ihi: '',
    photoTakenDate: null,
    suspendFromDate: null,
    suspendToDate: null,
    medicareNumber: '',
    dvaNum: '',
    diagnosis: [],
    gender: '',
    daaFunding: '',
    email: '',
    dob: null,
    firstName: '',
    lastName: '',
    preferredName: '',
    packingStatus: '',
    patientStatus: '',
    photoId: '',
    phone: '',
    doctorName: '',
    lastCheckedDate: '',
    lastCheckedUserName: '',
    isVisionImpaired: false,
    days30Dispensing: false,
    isNoAllergy: false,
    allergies: [],
    isAnySimilarNamePatient: false,
  },
};

const updateHeader = (state = initialState, action) => {
  switch (action.type) {
    case UpdateDetailsActions.UPDATE_PAITENT_PACKING_STATUS:
      return { ...state, data: { ...state.data, packingStatus: action.payload.packingStatus } };
    case UpdateBaseActions.GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...initialState,
        data: {
          patientGroupId: action.payload.data.patientGroupId,
          patientGroupName: action.payload.data.patientGroupName,
          area: action.payload.data.area,
          room: action.payload.data.room,
          urn: action.payload.data.urn,
          ihi: action.payload.data.ihi,
          photoTakenDate: action.payload.data.photoTakenDate,
          suspendFromDate: action.payload.data.suspendFromDate,
          suspendToDate: action.payload.data.suspendToDate,
          medicareNumber: action.payload.data.medicareNumber,
          dvaNum: action.payload.data.dvaNum,
          diagnosis: action.payload.data.diagnosis,
          gender: action.payload.data.gender,
          daaFunding: action.payload.data.daaFunding,
          email: action.payload.data.email,
          dob: action.payload.data.dob,
          firstName: action.payload.data.firstName,
          lastName: action.payload.data.lastName,
          preferredName: action.payload.data.preferredName,
          packingStatus: action.payload.data.packingStatus,
          patientStatus: action.payload.data.patientStatus,
          photoId: action.payload.data.photoId,
          phone: action.payload.data.phone,
          doctorName: action.payload.data.doctorName,
          lastCheckedDate: action.payload.data.lastCheckedDate,
          lastCheckedUserName: action.payload.data.lastCheckedUserName,
          isVisionImpaired: action.payload.data.visionImpaired || action.payload.data.isVisionImpaired,
          isVisionImpaired: action.payload.data.days30Dispensing || action.payload.data.days30Dispensing,
          isNoAllergy: action.payload.data.isNoAllergy,
          allergies: action.payload.data.allergies,
          isAnySimilarNamePatient: action.payload.data.isAnySimilarNamePatient,
        },
      };
    case UpdateDetailsActions.UPDATE_PATIENT_DETAILS_SUCCESS:
      return {
        ...initialState,
        data: {
          patientGroupId: action.payload.data.patientGroupId,
          patientGroupName: action.payload.data.patientGroupName,
          area: action.payload.data.area,
          room: action.payload.data.room,
          urn: action.payload.data.urn,
          ihi: action.payload.data.ihi,
          photoTakenDate: action.payload.data.photoTakenDate,
          suspendFromDate: action.payload.data.suspendFromDate,
          suspendToDate: action.payload.data.suspendToDate,
          medicareNumber: action.payload.data.medicareNumber,
          dvaNum: action.payload.data.dvaNum,
          diagnosis: action.payload.data.diagnosis,
          gender: action.payload.data.gender,
          daaFunding: action.payload.data.daaFunding,
          email: action.payload.data.email,
          dob: action.payload.data.dob,
          firstName: action.payload.data.firstName,
          lastName: action.payload.data.lastName,
          preferredName: action.payload.data.preferredName,
          packingStatus: action.payload.data.packingStatus,
          patientStatus: action.payload.data.patientStatus,
          photoId: action.payload.data.photoId,
          phone: action.payload.data.phone,
          doctorName: action.payload.data.doctorName,
          lastCheckedDate: action.payload.data.lastCheckedDate,
          lastCheckedUserName: action.payload.data.lastCheckedUserName,
          isVisionImpaired:
            action.payload.data.visionImpaired === 'true' || action.payload.data.isVisionImpaired === 'true',
          days30Dispensing:
            action.payload.data.days30Dispensing === 'true' || action.payload.data.days30Dispensing === 'true',
          isNoAllergy: action.payload.data.isNoAllergy,
          allergies: action.payload.data.allergies,
        },
      };
    case UpdateBaseActions.CLEAR_PATIENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default updateHeader;
