import { trim, get } from 'lodash';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

import { LAST_ACTION } from '../../constants';

export const VPB_HISTORY_CHANGE_FILTER = '@VPB/HISTORY_CHANGE_FILTER';
export const VPB_HISTORY_RESET_DATA = '@VPB/HISTORY_RESET_DATA';
export const VPB_HISTORY_UPDATE_DATA = '@VPB/HISTORY_UPDATE_DATA';

export const VPB_HISTORY_GET_VPB = '@VPB/VPB_HISTORY_GET_VPB';
export const VPB_HISTORY_GET_VPB_SUCCESS = '@VPB/VPB_HISTORY_GET_VPB_SUCCESS';
export const VPB_HISTORY_GET_VPB_FAIL = '@VPB/VPB_HISTORY_GET_VPB_FAIL';

export const VPB_HISTORY_MANUAL_ADJUST = '@VPB/HISTORY_MANUAL_ADJUST';
export const VPB_HISTORY_MANUAL_ADJUST_SUCCESS = '@VPB/HISTORY_MANUAL_ADJUST_SUCCESS';
export const VPB_HISTORY_MANUAL_ADJUST_FAIL = '@VPB/HISTORY_MANUAL_ADJUST_FAIL';

export const VPB_HISTORY_REVERT = '@VPB/HISTORY_REVERT';
export const VPB_HISTORY_REVERT_SUCCESS = '@VPB/HISTORY_REVERT_SUCCESS';
export const VPB_HISTORY_REVERT_FAIL = '@VPB/HISTORY_REVERT_FAIL';

export const historyChangeFilter = filter => {
	return dispatch => {
		dispatch({ type: VPB_HISTORY_CHANGE_FILTER, payload: filter });
	};
};

export const historyResetData = () => {
	return dispatch => {
		dispatch({ type: VPB_HISTORY_RESET_DATA });
	};
};

export const historyUpdateData = data => {
	return dispatch => {
		dispatch({ type: VPB_HISTORY_UPDATE_DATA, payload: data });
	};
};

export const historyGetVPB = vpBalanceId => {
	return dispatch => {
		dispatch({ type: VPB_HISTORY_GET_VPB });
		HttpService.get(`/api/vpbbalances/${vpBalanceId}`)
			.then(resp => {
				dispatch({ type: VPB_HISTORY_GET_VPB_SUCCESS, payload: parseModelToData(resp.data) });
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch({ type: VPB_HISTORY_GET_VPB_FAIL });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const historySaveManualAdjust = data => {
	return dispatch => {
		dispatch({ type: VPB_HISTORY_MANUAL_ADJUST });
		HttpService.post(`/api/vpbhistories`, data)
			.then(resp => {
				dispatch(
					AppActions.showMessage({
						message: 'vpb:MANUAL_ADJUST_MODAL_MESSAGE_SUCCESS',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
				dispatch({ type: VPB_HISTORY_MANUAL_ADJUST_SUCCESS, payload: LAST_ACTION.MANUAL_ADJUST });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({ type: VPB_HISTORY_MANUAL_ADJUST_FAIL });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

export const historyRevert = vpbHistoryId => {
	return dispatch => {
		dispatch({ type: VPB_HISTORY_REVERT });
		HttpService.put(`/api/vpbhistories/${vpbHistoryId}/revert`)
			.then(resp => {
				dispatch(
					AppActions.showMessage({
						message: 'vpb:HISTORY_REVERT_MESSAGE_SUCCESS',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
				dispatch({ type: VPB_HISTORY_REVERT_SUCCESS, payload: LAST_ACTION.REVERT_HISTORY });
			})
			.catch(err => {
				const { errorFields, errorMessages, errorTitle } = parseError(err);
				dispatch({ type: VPB_HISTORY_REVERT_FAIL });
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
			});
	};
};

function parseModelToData(model) {
	return {
		fullName: trim(`${get(model, 'firstName', '')} ${get(model, 'lastName', '')}`),
		currentBalanceQty: model?.balanceQty || 0,
		medication: model?.medication || ''
	};
}
